import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  PERMISSIONS = ['Admin', 'Editor'];

  @Input() email = '';
  @Input() permission: 'Admin' | 'Editor';
  @Input() uid = '';

  isNewUser = true;

  constructor(private helperServ: HelperService) {}

  ngOnInit() {
    if (this.email && this.permission) {
      this.isNewUser = false;
    }
  }

  permissionChanged(event) {
    this.permission = event.detail.value;
  }

  emailChanged(event) {
    this.email = event.detail.value;
  }

  submit() {
    this.close({
      email: this.email,
      permission: this.permission,
      uid: this.uid,
    });
  }

  async close(data?: any) {
    await this.helperServ.closeModal(data);
  }
}
