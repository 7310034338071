import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'ventures',
    loadChildren: () => import('./placement/placement.module').then((m) => m.PlacementPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'engage',
    loadChildren: () =>
      import('./engage/engage.module').then((m) => m.EngagePageModule),
  },
  {
    path: 'calculus',
    loadChildren: () =>
      import('./solutions/solutions.module').then((m) => m.SolutionsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then((m) => m.BillingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'admin-placement',
    loadChildren: () => import('./admin-placement/admin-placement.module').then( m => m.AdminPlacementPageModule)
  },
  {
    path: 'venture',
    loadChildren: () => import('./venture/venture.module').then( m => m.VenturePageModule)
  },
  {
    path: 'initiate',
    loadChildren: () => import('./initiate/initiate.module').then( m => m.InitiatePageModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then( m => m.PasswordPageModule)
  },
  {
    path: 'payment-processor',
    loadChildren: () => import('./payment-processor/payment-processor.module').then( m => m.PaymentProcessorPageModule)
  },
  {
    path: 'embed/:id',
    loadChildren: () => import('./embed/embed.module').then( m => m.EmbedPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
