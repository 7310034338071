import { Component, Input, OnInit } from '@angular/core';
import { SearchbarCustomEvent, ToggleCustomEvent } from '@ionic/angular';
import { getAddByCategory } from 'src/app/constants/cloud-functions';
import { Problem } from 'src/app/placement/builder/builder.page';
import { HelperService } from './../../services/helper.service';

interface Category {
  type: string;
  category: string;
  subCategory: string;
  question: string;
  answers: string;

  isSelected?: boolean;
}

export const PROBLEMS: Category[] = [
  // { type: 'Current Events' },
  // { type: 'Economy' },
  // { type: 'Entertainment' },
  // { type: 'Health' },
  // { type: 'Politics' },
  // { type: 'Science' },
  // {
  //   type: 'Sports',
  //   category: 'Baseball',
  // },
  // {
  //   type: 'Sports',
  //   category: 'Football',
  //   subCategory: 'Amateur',
  //   question: 'Which is best?',
  //   answers: 'D3, High School',
  //   isSelected: false,
  // },
  // {
  //   type: 'Sports',
  //   category: 'Football',
  //   subCategory: 'Professional',
  //   question: 'Which is best?',
  //   answers: 'College Football, NFL',
  //   isSelected: false,
  // },
  // { type: 'Style' },
  // { type: 'Technology' },
  // { type: 'Weather' },
  // { type: 'More' },
];

@Component({
  selector: 'app-choose-categories',
  templateUrl: './choose-categories.component.html',
  styleUrls: ['./choose-categories.component.scss'],
})
export class ChooseCategoriesComponent implements OnInit {
  @Input() problems: Problem[] = [];

  PROBLEMS: Category[] = [];

  types: string[] = [];

  categories: string[] = [];
  subCategories: string[] = [];
  questions: string[] = [];
  answers: string[] = [];
  isSelecteds: boolean[] = [];

  selectedCategories: Category[][] = [];
  finalCategory: Category;

  searchTerm = '';

  constructor(private helperServ: HelperService) {
    this.getAddByCategory();
  }

  ngOnInit() {}

  getAddByCategory() {
    getAddByCategory().then((res) => {
      if (res.data) {
        const probs = res.data as {
          category: string;
          count_: string;
          path: string;
          possible_answers: string;
          question: string;
          subcategory: string;
          type: string;
        }[];

        this.PROBLEMS = probs.map((prob) => {
          return {
            answers: prob.possible_answers,
            subCategory: prob.subcategory,
            category: prob.category,
            question: prob.question,
            type: prob.type,
          };
        });

        this.PROBLEMS.forEach((type, i) => {
          if (this.types.findIndex((type2) => type2 == type.type) == -1) {
            this.types.push(type.type);
          }
        });

        this.types.sort((a, b) => (a > b ? 1 : -1));

        this.problems.forEach((prob) => {
          const answers = prob.answers_split.join(', ');
          const foundIndex = this.PROBLEMS.findIndex(
            (prob2) =>
              prob2.question == prob.question && answers == prob2.answers
          );
          if (foundIndex != -1) {
            this.PROBLEMS[foundIndex].isSelected = true;
          }
        });
      }
    });
  }

  selectType(type: string, index: number) {
    this.PROBLEMS.filter((problem) => problem.type == type).forEach(
      (category, i) => {
        if (
          this.categories.findIndex(
            (category2) => category2 == category?.category
          ) == -1
        ) {
          this.categories.push(category?.category);
        }
      }
    );
    this.categories.sort((a, b) => (a > b ? 1 : -1));
  }

  selectCategory(category: string) {
    this.PROBLEMS.filter((problem) => problem.category == category).forEach(
      (subCategory, i) => {
        if (
          this.subCategories.findIndex(
            (subCategory2) => subCategory2 == subCategory?.subCategory
          ) == -1
        ) {
          this.subCategories.push(subCategory?.subCategory);
        }
      }
    );
    this.subCategories.sort((a, b) => (a > b ? 1 : -1));
  }

  selectSubCategory(subCategory: string) {
    const problems = this.PROBLEMS.filter(
      (problem) => problem.subCategory == subCategory
    ).sort((a, b) => (a.question > b.question ? 1 : -1));

    this.questions = problems.map((problem) => problem.question);
    this.answers = problems.map((problem) => problem.answers);
    this.isSelecteds = problems.map((problem) => problem.isSelected || false);
  }

  searchChanged(event: Event) {
    this.searchTerm = (event as SearchbarCustomEvent).detail.value;

    if (this.searchTerm == '') {
      this.questions = [];
      this.answers = [];
      this.isSelecteds = [];
      return;
    }

    const problems = this.PROBLEMS.filter(
      (problem) =>
        problem?.question?.toLowerCase().match(this.searchTerm.toLowerCase()) ||
        problem?.answers?.toLowerCase().match(this.searchTerm.toLowerCase())
    );

    this.questions = problems.map((problem) => problem.question);
    this.answers = problems.map((problem) => problem.answers);
    this.isSelecteds = problems.map((problem) => problem.isSelected || false);
  }

  toggleSelected(event: Event, question: string, answers: string) {
    const isSelected = (event as ToggleCustomEvent).detail.checked;
    const foundIndex = this.PROBLEMS.findIndex(
      (prob) => prob.question == question && prob.answers == answers
    );
    if (foundIndex != -1) {
      this.PROBLEMS[foundIndex].isSelected = isSelected;
    }
  }

  addToStudy() {
    const selected = this.PROBLEMS.filter((prob) => prob.isSelected).map(
      (prob) => {
        return { question: prob.question, answers: prob.answers };
      }
    );
    this.close(selected);
  }

  goBack() {
    if (this.questions.length > 0) {
      this.questions = [];
      this.answers = [];
    } else if (this.subCategories.length > 0) {
      this.subCategories = [];
    } else if (this.categories.length > 0) {
      this.categories = [];
    }
  }

  async close(data?: any) {
    await this.helperServ.closeModal(data);
  }
}
