import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { auth } from 'src/environments/environment';
import { 
  createExecutive,
  createWorkspace, 
  submitInitiateRequest,
  getNewInitiateRequests,
  updateInitiateRequest,
  acceptTac,
  updateExecutiveLastLogin,
  getExecutiveLastLogin,
  setPassword,
} from '../constants/cloud-functions';
import { ExecutiveInitiateRequest } from '../admin-placement/admin-placement.page';
import { ExecutiveLastLogin } from 'functions/src';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user: BehaviorSubject<{
    email?: string;
    uid?: string;
    permission?: 'Admin' | 'Editor';
  }> = new BehaviorSubject({});
  $user = this._user.asObservable();

  constructor(private router: Router) {
    auth.onAuthStateChanged(async (user) => {
      if (user?.uid) {
        this._user.next({ uid: user.uid, email: user.email });
        this.storeToken(await user.getIdToken());
      } else if (!user?.uid && this._user.value.uid) {
        this._user.next({});
        this.logout();
      }
    });
  }

  async logout() {
    await auth.signOut();
    this.removeToken();
    this.router.navigate(['login']);
  }

  storeToken(token: string) {
    localStorage.setItem('token', token);
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  submitInitiateRequest(
    name: string, 
    title: string, 
    company: string, 
    email: string, 
    phone: string, 
    company_billing_address: string,
  ) {
    submitInitiateRequest({
        name,
        title,
        company,
        email,
        phone,
        company_billing_address,
      });
  }

  async getNewInitiateRequests() {
    return getNewInitiateRequests().then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveInitiateRequest[];
          return data
        }
        return [];
      }
    );
  }

  async approveExecutive(email: string, workspace_name: string,) {
    await updateInitiateRequest({
      email: email,
      request_status: "Approved",
    });
  }

  async denyExecutive(email: string,) {
    await updateInitiateRequest({
      email: email,
      request_status: "Denied",
    })
  }

  async acceptTac(email: string, workspace_name: string,) {
    await acceptTac({
      email: email,
    })

    await this.generateHexString().then(async result => {
      let hexString = result;

      await createExecutive({
        email: email,
        password: hexString,
      }).then(async (res) => {
          const uid = res
  
          await createWorkspace({
            executive_id: uid.data,
            workspace_name: workspace_name,
          });
        }
      );
    });
  }

  async updateExecutiveLastLogin(email: string,) {
    await updateExecutiveLastLogin({
      email: email,
    })
  }

  async getExecutiveLastLogin(email: string,) {
    return getExecutiveLastLogin(email,).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveLastLogin[];
          return data
        }
        return [];
      }
    );
  }

  async generateHexString(): Promise<string> {
    let hexString = '';
    const possibleChars = '0123456789ABCDEF';

    for (let i = 0; i < 40; i++) {
        hexString += possibleChars[Math.floor(Math.random() * possibleChars.length)];
    }

    return hexString;
  }

  async setPassword(email: string, password: string,) {
    await setPassword({
      email: email,
      password: password,
    })
  }
}
