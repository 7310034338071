import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { AuthService } from './services/auth.service';
import { SettingsService } from './services/settings.service';
import { Workspace } from './settings/settings.page';
import { Storage } from '@ionic/storage-angular';
import { filter } from 'rxjs/operators';
import { StudyService } from './services/study.service';
import { HelperService } from './services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  logoUrl = 'assets/icon/Calculus_C_Logo_Transparent.png';
  workspace: Workspace = { name: '', id: '', type: ''};

  user: { email: string; uid: string; permission?: 'Admin' | 'Editor' };

  topPages = [
    {
      title: 'VENTURE',
      url: '/ventures/venture',
      iconName: 'exit-outline',
    },
    {
      title: 'CALCULUS',
      url: '/calculus',
      iconName: 'pie-chart-outline',
    },
  ];

  bottomPages = [
    {
      title: 'BILLING',
      url: '/billing',
      iconName: 'card-outline',
    },
    {
      title: 'SETTINGS',
      url: '/settings',
      iconName: 'settings-outline',
    },
  ];

  hideMenu: boolean = false;

  currentPath: string = '';

  constructor(
    private router: Router,
    private settingsServ: SettingsService,
    private authServ: AuthService,
    private storage: Storage,
    private studyServ: StudyService,
    private helperServ: HelperService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.urlAfterRedirects;
        this.hideMenu =
          this.currentPath.includes('login') ||
          this.currentPath.includes('landing') ||
          this.currentPath.includes('email') ||
          this.currentPath.includes('password') ||
          this.currentPath.includes('terms') ||
          this.currentPath.includes('initiate') ||
          this.currentPath.includes('engage') ||
          this.currentPath.includes('embed');
      }
    });

    this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((event: NavigationStart) => {
      // Check if the user is leaving the current page
      if (event.navigationTrigger === 'popstate') {
        // Perform any cleanup actions here
        console.log('Destroying current page or resetting its state');
      }
    });

    this.authServ.$user.subscribe(async (user) => {
      if (user.email && user.uid) {
        if (user.email != this.user?.email) {
          this.user = { ...this.user, email: user.email };
          await this.getWorkspace();
        }
        this.user = {
          email: user.email,
          uid: user.uid,
          permission: user?.permission,
        };
      }
    });
  }

  async ngOnInit() {
    await this.storage.create();
  }

  async getWorkspace() {
    this.settingsServ.getWorkspace(this.user.email).then((workspace) => {
      if (workspace) {
        this.workspace = workspace;
        localStorage.setItem('workspaceType',this.workspace.type);
      }

      if(this.workspace.type === 'admin') {
        this.topPages[0].url = '/admin-placement'
      }

      if(this.workspace.type === 'payment processor') {
        this.bottomPages[0].url = '/payment-processor'
      }
      
    });
  }

  async navigateWithId(page): Promise<void> {
    if (page.url === '/ventures/venture') {      
      await this.helperServ.presentLoader();

      const id = await this.generateHexString();

      await this.studyServ.createExecutiveStudy(id, this.workspace.id);   
  
      await this.helperServ.dismissLoader(); 
      
      this.router.navigate([page.url, id], { replaceUrl: true });
    } else {
      this.router.navigate([page.url], { replaceUrl: true });
    }
  }

  async generateHexString(): Promise<string> {
    let hexString = '';
    const possibleChars = '0123456789ABCDEF';
    for (let i = 0; i < 40; i++) {
      hexString += possibleChars[Math.floor(Math.random() * possibleChars.length)];
    }
    return hexString;
  }
}
