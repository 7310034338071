import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { AddUserComponent } from './../components/add-user/add-user.component';
import { ChooseCategoriesComponent } from './../components/choose-categories/choose-categories.component';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  loaders: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController
  ) {}

  async presentConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure?',
      buttons: [
        {
          text: 'Yes',
          role: 'confirm',
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
    return await alert.onWillDismiss();
  }

  async openCategories(data?: any) {
    const modal = await this.modalCtrl.create({
      component: ChooseCategoriesComponent,
      componentProps: data,
      cssClass: ['add-by-category'],
    });

    await modal.present();
    return modal;
  }

  async openAddUser(data?: any) {
    const modal = await this.modalCtrl.create({
      component: AddUserComponent,
      componentProps: data,
    });

    await modal.present();
    return modal;
  }

  async closeModal(data?: any) {
    return this.modalCtrl.dismiss(data);
  }

  async presentLoader() {
    const loading = await this.loadCtrl.create({
      spinner: 'circles',
      cssClass: 'dark-spinner-loader',
    });

    this.loaders.push(loading);

    if (this.loaders.length == 1) {
      await loading.present();
    }
  }

  async dismissLoader() {
    this.loaders.splice(0, 1);

    if (this.loaders.length == 0) {
      await this.loadCtrl.dismiss();
    }
  }
}
