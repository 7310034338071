import { https } from 'firebase-functions/v1';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/environments/environment';

export const createExecutive = httpsCallable(functions, 'createExecutive');
export const inviteExecutive = httpsCallable(functions, 'inviteExecutive');

export const editExecutive = httpsCallable(functions, 'editExecutive');
export const deleteExecutiveAuthentication = httpsCallable(
  functions,
  'deleteExecutiveAuthentication'
);

export const getWorkspace = httpsCallable(functions, 'getWorkspace');
export const getWorkspaceExecutives = httpsCallable(
  functions,
  'getWorkspaceExecutives'
);
export const createWorkspace = httpsCallable(functions, 'createWorkspace');
export const editWorkspace = httpsCallable(functions, 'editWorkspace');

export const submitInitiateRequest = httpsCallable(functions,'submitInitiateRequest');
export const getNewInitiateRequests = httpsCallable(functions,'getNewInitiateRequests');
export const updateInitiateRequest = httpsCallable(functions, 'updateInitiateRequest');

export const getSuggestions = httpsCallable(functions, 'getSuggestions');
export const getAddByCategory = httpsCallable(functions, 'getAddByCategory');
export const getMap = httpsCallable(functions, 'getMap');
export const getMapAboveAcross = httpsCallable(functions, 'getMapAboveAcross');
export const getMapAcross = httpsCallable(functions, 'getMapAcross');

export const updateMap = httpsCallable(functions, 'updateMap');
export const insertMap = httpsCallable(functions, 'insertMap');
export const deleteMap = httpsCallable(functions, 'deleteMap');

export const search = httpsCallable(functions, 'search');
export const categorySearch = httpsCallable(functions, 'categorySearch');
export const searchCoreAdmin = httpsCallable(functions,'searchCoreAdmin');

export const updateQuestion = httpsCallable(functions, 'updateQuestion');
export const updateAnswers = httpsCallable(functions, 'updateAnswers');
export const updateStatus = httpsCallable(functions, 'updateStatus');
export const deletePlacement = httpsCallable(functions, 'deletePlacement');
export const createPlacement = httpsCallable(functions, 'createPlacement');

export const getLinkMapStudyByStudy = httpsCallable(functions, 'getLinkMapStudyByStudy');
export const insertLinkMapStudy = httpsCallable(functions, 'insertLinkMapStudy');
export const getLinkMapStudyByCategory = httpsCallable(functions, 'getLinkMapStudyByCategory');
export const deleteLinkMapStudy = httpsCallable(functions, 'deleteLinkMapStudy');
export const deleteLinkMapStudyChildren = httpsCallable(functions, 'deleteLinkMapStudyChildren');
export const getMapByCategory = httpsCallable(functions, 'getMapByCategory');
export const updateMapRollingClose = httpsCallable(functions, 'updateMapRollingClose');
export const updateMapPctCompleteRequired = httpsCallable(functions, 'updateMapPctCompleteRequired');

export const getStudyByStudyId = httpsCallable(functions, 'getStudyByStudyId');
export const updateStudyRollingClose = httpsCallable(functions, 'updateStudyRollingClose');

export const getQualifyingCategoriesByCategory = httpsCallable(functions, 'getQualifyingCategoriesByCategory');
export const updatePctReqByLinkId = httpsCallable(functions, 'updatePctReqByLinkId');

export const searchQualifyingPlacementsCategoryDetail = httpsCallable(functions, 'searchQualifyingPlacementsCategoryDetail');
export const insertLinkQualifyingPlacementOperationalCategory = httpsCallable(functions, 'insertLinkQualifyingPlacementOperationalCategory');
export const getQualifyingPlacementsByCategory = httpsCallable(functions, 'getQualifyingPlacementsByCategory');
export const deleteLinkQualifyingPlacementOperationalCategory = httpsCallable(functions, 'deleteLinkQualifyingPlacementOperationalCategory');
export const updateQualifyingInputByLinkId = httpsCallable(functions, 'updateQualifyingInputByLinkId');
export const updateQualifyingInputByMapIdStudyId = httpsCallable(functions, 'updateQualifyingInputByMapIdStudyId');
export const getOperationalCategoriesByCategory = httpsCallable(functions, 'getOperationalCategoriesByCategory');

export const getQualifyingPlacementsByStudyId = httpsCallable(functions, 'getQualifyingPlacementsByStudyId');
export const deleteLinkQualifyingPlacementOperationalPlacement = httpsCallable(functions, 'deleteLinkQualifyingPlacementOperationalPlacement');
export const searchQualifyingPlacementsPlacementDetail = httpsCallable(functions, 'searchQualifyingPlacementsPlacementDetail');
export const insertLinkQualifyingPlacementOperationalPlacement = httpsCallable(functions, 'insertLinkQualifyingPlacementOperationalPlacement');
export const getOperationalPlacementsByStudyId = httpsCallable(functions, 'getOperationalPlacementsByStudyId');
export const searchOperationalPlacementsPlacementDetail = httpsCallable(functions, 'searchOperationalPlacementsPlacementDetail');
export const getQualifyingCategoriesByStudyId = httpsCallable(functions,'getQualifyingCategoriesByStudyId');
export const deleteLinkQualifyingCategoryOperationalPlacement = httpsCallable(functions,'deleteLinkQualifyingCategoryOperationalPlacement');
export const insertLinkQualifyingCategoryOperationalPlacement = httpsCallable(functions,'insertLinkQualifyingCategoryOperationalPlacement');

export const updateLinkMapStudyOrder = httpsCallable(functions, 'updateLinkMapStudyOrder');
export const updateMoveCategory = httpsCallable(functions, 'updateMoveCategory');
export const deleteLinkQualifyingCategoryOperationalCategory = httpsCallable(functions,'deleteLinkQualifyingCategoryOperationalCategory');
export const insertLinkQualifyingCategoryOperationalCategory = httpsCallable(functions,'insertLinkQualifyingCategoryOperationalCategory');

export const createExecutiveStudy = httpsCallable(functions, 'createExecutiveStudy');
export const getExecutiveStudy = httpsCallable(functions, 'getExecutiveStudy');
export const getExecutivePlacementsByExStudyId = httpsCallable(functions, 'getExecutivePlacementsByExStudyId');
export const updateExecutivePlacementQuestion = httpsCallable(functions, 'updateExecutivePlacementQuestion');
export const updateExecutivePlacementAnswers = httpsCallable(functions, 'updateExecutivePlacementAnswers');
export const createExecutivePlacement = httpsCallable(functions, 'createExecutivePlacement');
export const deleteExecutivePlacement = httpsCallable(functions, 'deleteExecutivePlacement');
export const updateExecutiveStudyName = httpsCallable(functions, 'updateExecutiveStudyName');
export const updateExecutiveStudyFrequency = httpsCallable(functions, 'updateExecutiveStudyFrequency');
export const updateExecutiveStudyDate = httpsCallable(functions, 'updateExecutiveStudyDate');
export const updateExecutiveStudyStatus = httpsCallable(functions, 'updateExecutiveStudyStatus');

export const adminGetExecutiveStudies = httpsCallable(functions, 'adminGetExecutiveStudies');
export const adminGetExecutivePlacementsByExStudyId = httpsCallable(functions,'adminGetExecutivePlacementsByExStudyId');
export const insertLinkExPlacementStudy = httpsCallable(functions, 'insertLinkExPlacementStudy');

export const getExecutiveStudySolutions = httpsCallable(functions, 'getExecutiveStudySolutions');
export const getExecutiveStudySolution = httpsCallable(functions, 'getExecutiveStudySolution');
export const getExecutiveSolutionsByExStudyId = httpsCallable(functions, 'getExecutiveSolutionsByExStudyId');
export const getExecutiveStudySolutionDatesByExStudyId = httpsCallable(functions, 'getExecutiveStudySolutionDatesByExStudyId');

export const createPublishedCalculus = httpsCallable(functions, 'createPublishedCalculus');
export const getPublishedCalculusSolutionDates = httpsCallable(functions, 'getPublishedCalculusSolutionDates');
export const getPublishedCalculusSolution = httpsCallable(functions, 'getPublishedCalculusSolution');
export const updatePublishedCalculusEnhancedPosition = httpsCallable(functions, 'updatePublishedCalculusEnhancedPosition');
export const updatePublishedCalculusStatus = httpsCallable(functions, 'updatePublishedCalculusStatus');
export const getPublishedCalculusCategoryLinks = httpsCallable(functions, 'getPublishedCalculusCategoryLinks');
export const getPublishedCalculusStudyLinksByCategory = httpsCallable(functions, 'getPublishedCalculusStudyLinksByCategory');
export const insertPublishedCalculusEnhancement = httpsCallable(functions, 'insertPublishedCalculusEnhancement');
export const deletePublishedCalculusEnhancement = httpsCallable(functions, 'deletePublishedCalculusEnhancement');
export const getPublishedCalculusEnhancements = httpsCallable(functions, 'getPublishedCalculusEnhancements');
export const getPublishedCalculusEnhancementsPlacements = httpsCallable(functions, 'getPublishedCalculusEnhancementsPlacements');
export const updatePublishedCalculusDistribution = httpsCallable(functions, 'updatePublishedCalculusDistribution');
export const getPublishedCalculusSolutionDatesByExPlacementId = httpsCallable(functions, 'getPublishedCalculusSolutionDatesByExPlacementId');
export const getExecutiveSolution = httpsCallable(functions, 'getExecutiveSolution');
export const getPublishedCalculusSolutionsByExPlacementId = httpsCallable(functions, 'getPublishedCalculusSolutionsByExPlacementId');
export const getPublishedCalculusEnhancementsCategories = httpsCallable(functions, 'getPublishedCalculusEnhancementsCategories');

export const searchExecutiveStudies = httpsCallable(functions,'searchExecutiveStudies');
export const searchExecutiveStudySolutions = httpsCallable(functions,'searchExecutiveStudySolutions');

export const acceptTac = httpsCallable(functions,'acceptTac');
export const updateExecutiveLastLogin = httpsCallable(functions,'updateExecutiveLastLogin');
export const getExecutiveLastLogin = httpsCallable(functions,'getExecutiveLastLogin');
export const setPassword = httpsCallable(functions,'setPassword');

export const getExecutiveInvoices = httpsCallable(functions,'getExecutiveInvoices');
export const getDueInvoices = httpsCallable(functions,'getDueInvoices');
export const searchInvoices = httpsCallable(functions,'searchInvoices');
export const updateExecutiveInvoiceStatus = httpsCallable(functions,'updateExecutiveInvoiceStatus');

export const getEmbedSolution = httpsCallable(functions,'getEmbedSolution');