import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  deleteExecutiveAuthentication,
  editExecutive,
  editWorkspace,
  getWorkspace,
  getWorkspaceExecutives,
  inviteExecutive,
  getExecutiveInvoices,
  getDueInvoices,
  searchInvoices,
  updateExecutiveInvoiceStatus,
} from '../constants/cloud-functions';
import { Workspace } from './../settings/settings.page';
import { ExecutiveInvoice } from '../billing/billing.page';

export enum ROLE {
  'Admin' = 'Admin',
  'Editor' = 'Editor',
}

export interface WorkspaceExecutives {
  uid: string;
  email: string;
  permission: string;
  linkId: string;
}
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _workspace: BehaviorSubject<Workspace> = new BehaviorSubject(null);
  $workspace = this._workspace.asObservable();

  constructor() {}

  async inviteExecutive(email: string, role: ROLE, workspace_id: string) {
    await inviteExecutive({ email, role, workspace_id });
  }

  async editExecutive(role: ROLE, executive_id: string, workspace_id: string) {
    await editExecutive({ role, workspace_id, executive_id });
  }

  async deleteExecutive(uid: string) {
    await deleteExecutiveAuthentication({ uid });
  }

  async getWorkspace(email: string): Promise<Workspace> {
    return getWorkspace({ email }).then((res) => {
      if (res.data && Array.isArray(res.data)) {
        this._workspace.next({
          name: res.data[0]?.workspace_name,
          id: res.data[0]?.workspace_id,
          type: res.data[0]?.workspace_type,
        });

        return {
          name: res.data[0]?.workspace_name,
          id: res.data[0]?.workspace_id,
          type: res.data[0]?.workspace_type,
        };
      }
      return {
        name: (res.data as any).workspace_name,
        id: (res.data as any).workspace_id,
        type: res.data[0]?.workspace_type,
      };
    });
  }

  async getWorkspaceExecutives(
    workspace_id: string
  ): Promise<WorkspaceExecutives[]> {
    return getWorkspaceExecutives({ workspace_id }).then((res) => {
      if (res.data && Array.isArray(res.data)) {
        return res.data.map((user) => {
          return {
            uid: user.executive_id,
            email: user.executive_email,
            permission: user.executive_role,
            linkId: user.link_id,
          };
        });
      }
      return [res.data as WorkspaceExecutives];
    });
  }

  async editWorkspace(workspace_id: string, name: string) {
    await editWorkspace({ workspace_id, name });
  }

  async getExecutiveInvoices(workspace_id: string) {
    return getExecutiveInvoices({workspace_id}).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveInvoice[];
          return data
        }
        return [];
      }
    );
  }

  async getDueInvoices() {
    return getDueInvoices().then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveInvoice[];
          return data
        }
        return [];
      }
    );
  }

  async searchInvoices(search_string: string) {
    return searchInvoices({search_string}).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveInvoice[];
          return data
        }
        return [];
      }
    );
  }

  async updateExecutiveInvoiceStatus(id: string, status: string,) {
    return updateExecutiveInvoiceStatus({id, status}).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveInvoice[];
          return data
        }
        return [];
      }
    );
  }
}
